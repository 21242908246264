/* eslint-disable import/exports-last */
import { graphql, navigate } from 'gatsby';

import DynamicZone from '../../../../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../../../../components/floating-button/floating-button';
import Layout from '../../../../components/layout';
import React from 'react';

const RemoteClientAgreementsPage = (props) => {
    const components = props.data.cms.remoteClientAgreementsPageTemplate?.components || null;

    if (!components) {
        if (typeof window !== `undefined`) {
            navigate(`${props.pageContext.locale === `pl` ? `/pl` : ``}/404`);
        }
        return null;
    }

    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <FloatingButton />
            <DynamicZone components={components} />
        </Layout>
    );
};

export default RemoteClientAgreementsPage;

export const query = graphql`
    query ($locale: String!) {
        cms {
            remoteClientAgreementsPageTemplate(locale: $locale) {
                components {
                    ... on STRAPI_ComponentComponentsHeader {
                        __typename
                        title
                        headerSubtitle: subtitle
                        contentOffset
                        mobileContentOffset
                        primaryImage {
                            url
                        }
                        secondaryImage {
                            url
                        }
                        backgroundImage {
                            url
                        }
                        backgroundImageHeight
                    }
                    ... on STRAPI_ComponentUseCasesUseCasesSection {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        sectionTitle {
                            tailwindClasses
                            title
                            style
                            description
                            ytLink
                        }
                        UseCasesColumns {
                            title
                            subtitle
                            icon {
                                url
                            }
                            backgroundImage {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentComponentsBenefits {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        benefitsScrollColor
                        mobileItemsToLeft
                        imagesTailwindClasses
                        sectionTitle {
                            tailwindClasses
                            title
                            style
                            description
                            ytLink
                        }
                        Benefit {
                            image {
                                url
                            }
                            title
                            subtitle
                        }
                    }
                    ... on STRAPI_ComponentHorizontalTabsHorizontalTabs {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        tabs {
                            images {
                                url
                            }
                            scrollColor
                            imagesMetadata
                            imagesTitles
                            label
                            link
                            type
                            title
                            subtitle
                            isLabelImageVisibleOnDesktop
                        }
                        tabHeight
                        sectionTitle {
                            tailwindClasses
                            style
                            title
                            description
                            ytLink
                        }
                    }
                    ... on STRAPI_ComponentComponentsImagesWithOffset {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        offsetComponentType
                        imagesWithOffsetScrollColor
                        contentOffset
                        sectionTitle {
                            tailwindClasses
                            description
                            style
                            title
                            ytLink
                        }
                        content {
                            title
                            subtitle
                            image {
                                url
                            }
                        }
                    }
                    ... on STRAPI_ComponentComponentsSingleImage {
                        __typename
                        topPusherHeightMobile
                        topPusherHeight
                        image {
                            url
                        }
                    }
                    ... on STRAPI_ComponentSeoSeo {
                        __typename
                        seoDescription
                        seoLink
                        seoTitle
                        seoImages {
                            url
                        }
                    }
                }
            }
        }
    }
`;
